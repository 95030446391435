html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
   margin: 0;
   padding: 0;
   border: 0;
   font: inherit;
   line-height: normal;
   vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
   display: block;
}
body {
   line-height: 1;
}
ol,
ul {
   list-style: none;
}
blockquote,
q {
   quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
   content: none;
}
table {
   border-collapse: collapse;
   border-spacing: 0;
}
a {
   text-decoration: none;
}
a:not(:disabled),
button:not(:disabled) {
   cursor: pointer;
}
a:focus,
a:hover,
button:focus,
button:hover {
   outline: 0;
   text-decoration: none;
}
th {
   text-align: inherit;
}
img,
svg {
   vertical-align: middle;
}
@-ms-viewport {
   width: auto !important;
}
