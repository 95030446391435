@import 'shared/styles/variables';

.root {
   display: flex;
   flex-direction: column;
}
.options {
   margin: 5px 0;
   display: flex;
   > div {
      margin-right: 30px;
   }
   > div:last-of-type {
      margin-right: 0;
   }
   &.vertical {
      flex-direction: column;
      > div {
         margin-bottom: 10px;
         white-space: pre-wrap;
      }
      > div:last-of-type {
         margin-bottom: 0;
      }
   }
}
