@use "sass:math";
@import 'shared/styles/colors';

$main-font: 'Open Sans';
$input-font: 'Verdana';
$label-font: 'ScalaSansPro';
$icon-font: 'iconfont';

$header-height: 60px;

$input-focused-border: solid 2px $dela-blue-100;
$input-error-border: 1px solid $red-100;
$input-bright-border: solid 1px $grey-60;
$input-bright-disabled-border: solid 1px $grey-60;
$input-bright-error-border: 1px solid $red-100;

$input-placeholder-color: $grey-60;
$input-disabled-bg-color: $grey-20;

$button-border-radius: 4px;

$table-border: solid 2px $grey-20;

$rembase: 14;
$text-size-small: rem(10);
$text-size-normal: 1rem;
$text-size-large: rem(17);
$text-size-larger: rem(19);
$text-size-largest: rem(20);

$beneficiary-first-color: $yellow-100;
$beneficiary-second-color: $blue-100;
$beneficiary-third-color: $orange-100;
$beneficiary-fourth-color: $green-100;

$form-right-part-width: 220px;

$popover-z-index: 10;

@function rem($pxsize) {
   @return math.div($pxsize, $rembase) * 1rem;
}
