@import 'shared/styles/variables';

.remove-dialog {
   width: 100%;
   padding: 20px 50px 5px;
   text-align: center;
   background-color: $dela-blue-40;
   .btn-flat {
      font-size: rem(12);
      font-weight: normal;
   }
   button,
   button:focus {
      margin-bottom: 10px;
   }
   .options {
      display: flex;
      justify-content: center;
      label {
         color: $grey-120;
         font-weight: bold;
         font-size: 1rem;
         padding: 10px 0;
         position: relative;
         text-transform: uppercase;
         font-family: $main-font;
         white-space: nowrap;
         &:first-of-type {
            margin-right: 10px;
         }
      }
      input[type='radio'] {
         opacity: 0;
         position: absolute;
         & + label:before {
            content: '';
            background: $dela-blue-40;
            border: 3px solid $dela-blue-100;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 6px;
            text-align: center;
            border-radius: 50%;
         }
         &:checked + label:before {
            background: $dela-blue-100;
            border: 3px solid $dela-blue-100;
            box-shadow: inset 0 0 0 3px $dela-blue-40;
         }
      }
   }
}
