@import 'shared/styles/variables';

.nav-button,
.nav-button:focus {
   box-sizing: border-box;
   display: inline-block;
   position: relative;
   font-family: $main-font;
   color: $dela-blue-100;
   background: none;
   font-size: rem(14);
   font-weight: normal;
   height: 32px;
   line-height: 31px;
   border: 1px solid $dela-blue-100;
   border-radius: $button-border-radius;
   padding: 0 20px;

   &.disabled {
      color: $grey-60;
      border-color: $grey-60;
      pointer-events: none;
   }

   &:not(.disabled) {
      &:hover {
         background-color: $dela-blue-80;
         border-color: $dela-blue-80;
      }
      &:active {
         background-color: $dela-blue-100;
         border-color: $dela-blue-100;
      }

      &:hover,
      &:active {
         color: $white-100;
      }
   }

   &:before,
   &:after {
      font-family: $icon-font;
      font-size: rem(10);
      position: absolute;
      font-weight: 600;
   }
   &:not(.next) {
      padding-left: 25px;
      &:before {
         content: '\e81e';
         left: 10px;
      }
   }
   &.next {
      padding-right: 25px;

      & button {
         background-color: $white-100;
      }
      &:after {
         content: '\e81f';
         right: 10px;
      }
   }
}

a.nav-button.next,
button.nav-button.next {
   background-color: $dela-blue-10;
   &.disabled {
      background: none;
   }
}
