@import 'shared/styles/variables';

.not-found {
   margin: 15vh 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   &-image {
      position: relative;
      width: 250px;
      height: 245px;
      top: 0;
      left: 0;
      margin-bottom: 25px;
   }

   &-header {
      color: $dela-blue-100;
      font-size: rem(28);
      font-weight: normal;
      text-transform: none;
      margin-bottom: 17px;
      letter-spacing: 1.29px;
   }

   p {
      color: $grey-100;
      font-size: rem(18);
      font-family: $main-font;
      letter-spacing: 1.29px;
      text-align: center;
   }

   &-description {
      margin-bottom: 30px;
   }

   &-homepage {
      margin-bottom: 45px;
   }
}
