@use 'shared/styles/reset';
@import 'shared/styles/variables';
@import 'shared/styles/fonts';
@import 'shared/styles/buttons';
@import 'shared/styles/icons';
@import 'shared/styles/toasts';
@import 'shared/styles/loader';
@import 'shared/styles/mixins';

html {
   font-size: 14px;
   font-family: $main-font;
}

body {
   margin: 0;
   padding: 0;
   min-width: 1024px;
}

.shifted {
   @include testEnvNotification;
}

h1,
h2,
h3 {
   font-weight: bold;
   color: $dela-blue-100;
}

h2,
h3 {
   text-transform: uppercase;
}

h1,
h4,
h6 {
   font-family: $input-font;
}

h1 {
   font-size: $text-size-largest;
}

h2 {
   font-size: $text-size-larger;
}

h3 {
   font-size: $text-size-large;
}

h4,
h5 {
   font-size: $text-size-normal;
}

h6 {
   font-size: $text-size-small;
}

a {
   cursor: pointer;
}

label {
   font-family: $label-font;
}

input:-webkit-autofill {
   box-shadow: 0 0 0 30px $white-100 inset;
   -webkit-text-fill-color: $grey-100;
   &:disabled {
      box-shadow: 0 0 0 30px $input-disabled-bg-color inset;
   }
   &.bright:disabled {
      box-shadow: 0 0 0 30px $grey-20 inset;
   }
}
//hide autocomplete icon on Safari
input::-webkit-contacts-auto-fill-button {
   visibility: hidden;
   display: none !important;
   pointer-events: none;
   position: absolute;
   right: 0;
}
