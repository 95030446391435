@import 'shared/styles/variables';

$container-width: 700px;

.toast-container {
   top: 0;
   left: calc(50% - $container-width / 2);
   margin: 0 auto;
   margin-top: $header-height;
   padding: 0;
   opacity: 0.88;
   width: $container-width;

   .toast {
      &-item {
         border-radius: 8px;
         background-color: $grey-120;
         min-height: initial;
         font-family: $input-font;
         font-size: rem(14);
         padding: 16px;
         margin-bottom: 4px;

         &-body {
            margin: 0;
            display: flex;
            .iconfont {
               flex: 1 0;
               &:first-of-type {
                  font-size: rem(28);
                  margin-right: 16px;
               }
               &:last-of-type {
                  font-size: rem(14);
                  margin-left: 16px;
               }
               &.icon-circle-check {
                  color: $green-100;
               }
               &.icon-triangle-exclamation {
                  color: $yellow-100;
               }
               &.icon-circle-exclamation {
                  color: $red-100;
               }
            }
            .toast-content {
               flex: 20 1;
               align-self: center;
            }
         }
      }
   }
}

@media only screen and (max-width: 480px) {
   .toast-container {
      width: 100vw;
      margin-left: 0;
   }
}
