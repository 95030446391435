@import 'shared/styles/variables';

.input-dropdown-wrapper {
   position: relative;
   .dropdown-input {
      margin: 0;
      font-family: $input-font;
      &.is-disabled .dropdown-input__control {
         background-color: $grey-20;
         border: $input-bright-disabled-border;
         height: 33px;
      }
      .dropdown-input__control {
         box-shadow: none !important;
         height: auto;
         outline: none;
         border: $input-bright-border;
         border-radius: 2px;
         min-height: 33px;
         &--menu-is-open {
            .dropdown-input__indicator-arrow {
               transform: scaleX(0.8) rotate(-45deg) !important;
               top: 2px !important;
            }
         }
         .dropdown-input__value-container {
            .dropdown-input__placeholder {
               color: $grey-60;
            }
            .dropdown-input__input {
               height: auto;
               input {
                  padding: 0;
                  margin: 0;
                  color: $grey-100 !important;
               }
            }
            .dropdown-input__single-value {
               color: $grey-100 !important;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               margin: 0;
               + div {
                  margin: 0;
               }
            }
         }
         .dropdown-input__indicators {
            cursor: pointer;
            .dropdown-input__indicator {
               padding: 5px;
            }
         }
      }
      .dropdown-input__menu {
         box-shadow: none;
         border-radius: 2px;
         margin-top: 3px;
         border: $input-bright-border;
         .dropdown-input__menu-list {
            padding: 0;
         }
         .dropdown-input__option {
            padding: 7px 10px;
            letter-spacing: 0.7px;
            color: $grey-100;
            &--is-selected {
               background-color: $dela-blue-10;
            }
            &--is-focused {
               background-color: $grey-20;
            }
            &--is-focused:first-child,
            &--is-selected:first-child {
               border-radius: 2px 2px 0 0;
            }
            &--is-focused:last-child,
            &--is-selected:last-child {
               border-radius: 0 0 2px 2px;
            }
            &--is-disabled {
               color: $grey-60;
            }
         }
      }
      &--is-disabled {
         .dropdown-input__control {
            background-color: $grey-20;
            border: solid 1px $grey-60 !important;
            height: 33px;
         }
         .dropdown-input__menu {
            display: none;
            pointer-events: none;
         }
      }
   }
   &.hasError .dropdown-input__control {
      border: $input-error-border !important;
   }
}

.short {
   & > .dropdown-input {
      float: left;
   }
   .postfix {
      margin-left: 9px;
      line-height: 30px;
      color: $dela-blue-100;
   }
}
