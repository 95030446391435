@import 'shared/styles/variables';

.root {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.textAreaWrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
}

.textArea {
   flex: 1;
   overflow: auto;
   resize: none;
   font-family: $input-font;
   outline: none;
   padding: 6px 9px 8px 9px;
   border: $input-bright-border;
   border-radius: 2px;
   letter-spacing: 0.7px;
   color: $grey-100;

   &::placeholder {
      color: $input-placeholder-color;
   }

   &:-ms-input-placeholder {
      color: $input-placeholder-color;
   }

   &:active,
   &:focus {
      border: $input-focused-border;
      padding: 5px 8px 7px 8px;
   }

   &:disabled {
      background-color: $grey-20;
      border: $input-bright-disabled-border;
      padding: 6px 9px 8px 9px;
      &::placeholder {
         opacity: 1;
         color: $input-placeholder-color;
         -webkit-text-fill-color: $input-placeholder-color;
      }
      &:-ms-input-placeholder {
         color: $input-placeholder-color;
      }
   }
   &.hasError {
      border: $input-error-border;
      padding: 6px 9px 8px 9px;
   }
}
