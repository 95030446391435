@import 'shared/styles/variables';

.circles-loader {
   position: absolute;
   margin-left: auto;
   margin-right: auto;
   left: 0;
   right: 0;
   span {
      display: inline-block;
      height: 9px;
      width: 8px;
      background-color: $white-100;
      border-radius: 50%;
      margin: 0 3px;
      animation: circles-blink 1.4s infinite both;

      &:first-child {
         margin-left: 0;
      }

      &:nth-child(2) {
         animation-delay: 0.2s;
      }

      &:nth-child(3) {
         animation-delay: 0.4s;
         margin-right: 0;
      }
   }
}

.bright {
   .circles-loader {
      span {
         background-color: $grey-60;
      }
   }
}

@keyframes circles-blink {
   0% {
      opacity: 0.2;
   }
   20% {
      opacity: 1;
   }
   100% {
      opacity: 0.2;
   }
}
