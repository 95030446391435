@import 'shared/styles/variables';

.spinner {
   display: block;
   border: 5px solid $grey-20;
   border-top-color: $dela-blue-100;
   border-radius: 50%;
   width: 50px;
   height: 50px;
   margin: 20px auto;
   animation: spin 1s linear infinite;

   &.fixed {
      position: fixed;
      left: 50%;
      top: 50%;
      margin: -80px 0 0 -25px;
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
