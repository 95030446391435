@import 'shared/styles/variables';

.info-panel {
   width: 100%;
   font-family: $input-font;
   font-size: rem(12);
   color: $dela-blue-100;
   letter-spacing: 0.6px;
   background-color: $grey-20;
   border-left: 8px solid $grey-100;
   border-radius: 2px;
   padding: 10px;

   &.highlight {
      background-color: $orange-20;
   }

   &.no-border {
      border: none;
   }
}
