@import 'shared/styles/variables';

.status-indicator {
   display: flex;
   align-items: center;
   font-family: $input-font;
   font-weight: bold;
   letter-spacing: 0.6px;
   color: $grey-60;
   margin-top: 9px;
   text-transform: uppercase;

   .circle {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $grey-60;
      margin-right: 5px;
   }

   .status-draft {
      background-color: $grey-60;
   }

   .status-completed {
      background-color: $green-40;
   }

   .status-cancelled {
      background-color: $orange-40;
   }

   .status-in-progress {
      background-color: $yellow-60;
   }
}
