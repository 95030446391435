@import 'shared/styles/variables';

.iconfont {
   font-family: $icon-font;
   font-style: normal;
   font-weight: normal;
   speak: none;

   display: inline-block;
   text-decoration: inherit;
   width: 1em;
   text-align: center;
   /* opacity: .8; */

   /* For safety - reset parent styles, that can break glyph codes*/
   font-variant: normal;
   text-transform: none;

   /* fix buttons height, for twitter bootstrap */
   line-height: 1em;

   /* Animation center compensation - margins should be symmetric */
   /* remove if not needed */

   /* you can be more comfortable with increased icons size */
   /* font-size: 120%; */

   /* Font smoothing. That was taken from TWBS */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;

   &.icon-cross:before {
      content: '\e800';
   }
   &.icon-square-person:before {
      content: '\e801';
   }
   &.icon-square-check-filled:before {
      content: '\e802';
   }
   &.icon-circle-plus:before {
      content: '\e803';
   }
   &.icon-person:before {
      content: '\e804';
   }
   &.icon-triangle-exclamation:before {
      content: '\e805';
   }
   &.icon-person-docs-filled:before {
      content: '\e823';
   }
   &.icon-square-info-filled:before {
      content: '\e807';
   }
   &.icon-person-tools:before {
      content: '\e808';
   }
   &.icon-docs-person:before {
      content: '\e809';
   }
   &.icon-circle:before {
      content: '\e80a';
   }
   &.icon-box:before {
      content: '\e80b';
   }
   &.icon-contacts:before {
      content: '\e80c';
   }
   &.icon-location:before {
      content: '\e80d';
   }
   &.icon-globe:before {
      content: '\e80e';
   }
   &.icon-docs:before {
      content: '\e80f';
   }
   &.icon-drop-info:before {
      content: '\e810';
   }
   &.icon-tools:before {
      content: '\e811';
   }
   &.icon-arrow-down:before {
      content: '\e812';
   }
   &.icon-circle-arrows:before {
      content: '\e813';
   }
   &.icon-pencil:before {
      content: '\e814';
   }
   &.icon-circle-check:before {
      content: '\e815';
   }
   &.icon-circle-exclamation:before {
      content: '\e816';
   }
   &.icon-arrow-up:before {
      content: '\e817';
   }
   &.icon-circle-forbidden:before {
      content: '\e818';
   }
   &.icon-check:before {
      content: '\e819';
   }
   &.icon-doc-filled:before {
      content: '\e81a';
   }
   &.icon-circle-filled:before {
      content: '\e81b';
   }
   &.icon-home:before {
      content: '\e81c';
   }
   &.icon-logout:before {
      content: '\e81d';
   }
   &.icon-arrow-left:before {
      content: '\e81e';
   }
   &.icon-arrow-right:before {
      content: '\e81f';
   }
   &.icon-magnifier:before {
      content: '\e820';
   }
   &.icon-circle-phone:before {
      content: '\e82f';
   }
   &.icon-circle-check-filled:before {
      content: '\e821';
   }
   &.icon-circle-exclamation-filled:before {
      content: '\e822';
   }
   &.icon-circle-cross-filled:before {
      content: '\e824';
   }
   &.icon-folder-drop-info:before {
      content: '\e825';
   }
   &.icon-envelope:before {
      content: '\e826';
   }
   &.icon-rectangle-question-chat:before {
      content: '\e827';
   }
   &.icon-calculator:before {
      content: '\e828';
   }
   &.icon-line-angled:before {
      content: '\e829';
   }
   &.icon-circle-dash-filled:before {
      content: '\e82a';
   }
   &.icon-circle-question-filled:before {
      content: '\e82b';
   }
   &.icon-docs-euro-filled:before {
      content: '\e82c';
   }
   &.icon-circle-half-filled:before {
      content: '\e82d';
   }
   &.icon-one-person-filled:before {
      content: '\e82e';
   }
   &.icon-two-people-filled:before {
      content: '\e831';
   }
   &.icon-email:before {
      content: '\e832';
   }
}

.how-it-works-icon {
   &:before {
      content: '\e807';
      font-family: $icon-font;
      font-size: rem(18);
      margin-right: 7px;
   }
}
