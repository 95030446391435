@import 'shared/styles/variables';

.button,
.button:focus {
   font-family: $main-font;
   box-sizing: border-box;
   color: $white-100;
   font-size: rem(15);
   height: auto;
   min-height: 32px;
   border-radius: $button-border-radius;
   padding: 0 20px;
   background-color: $grey-60;

   &:hover {
      background-color: $grey-20;
   }
   &:active {
      background-color: $grey-100;
   }

   &.save,
   &.save:focus {
      background-color: $green-100;
      &:hover {
         background-color: $green-60;
      }
      &:active {
         background-color: $green-120;
      }
      &:disabled {
         background-color: $green-40;
      }
   }

   &.action,
   &.action:focus {
      background-color: $orange-100;
      &:hover {
         background-color: $orange-60;
      }
      &:active {
         background-color: $orange-120;
      }
   }

   &.form-action,
   &.form-action:focus {
      background-color: $dela-blue-80;
      &:hover {
         background-color: $dela-blue-60;
      }
      &:active {
         background-color: $dela-blue-100;
      }
   }

   &.action,
   &.form-action {
      border: 1px solid transparent;
      &:disabled {
         color: $grey-60;
         border: 1px solid $grey-60;
         background: none;
      }
      .circles-loader span {
         background-color: $grey-60;
      }
      &.bright {
         &:disabled {
            color: $white-100;
            border-color: $white-100;
         }
         .circles-loader span {
            background-color: $white-100;
         }
      }
   }

   &.handling,
   &.handling:disabled,
   &.handling.bright:disabled {
      display: flex;
      align-items: center;
      position: relative;
      color: transparent;
   }

   &.copy {
      font-size: rem(12);
      padding-left: 45px;
      position: relative;
      &:before {
         content: '\e80f';
         font-family: $icon-font;
         font-size: rem(22);
         position: absolute;
         font-weight: normal;
         left: 15px;
         top: 5px;
      }
      &:disabled:before {
         color: $grey-60;
      }
   }
   &.link {
      font-weight: bold;
      text-transform: none;
      text-decoration: underline;
      background: none;
      padding: 0;
      height: unset;
      &:disabled {
         color: $grey-60;
      }
   }
}
