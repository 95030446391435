.root {
   display: flex;
   flex-direction: column;
}

.inputWrapper {
   display: flex;
   flex-direction: column;
   flex: auto;
}
