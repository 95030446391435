@import 'shared/styles/variables';

.dropdown-input__indicators .loader {
   position: relative;
   top: 0;
   left: 0;
   border-width: 3px;
   width: 20px;
   height: 20px;
   margin: 0 10px;
}
