@import 'shared/styles/variables';
.root {
   display: flex;
   flex-direction: column;
}
.checkboxWrapper {
   display: flex;
   margin-bottom: 15px;
   position: relative;
}

.label {
   display: flex;
   color: $dela-blue-100;
   letter-spacing: 0.7px;
   line-height: 18px;
   text-align: left;
   &:before {
      flex-shrink: 0;
   }
}

.labelText {
   font-family: $input-font;
}

.required:after {
   font-weight: normal;
   content: '*';
}

.checkbox {
   opacity: 0;
   position: absolute;
   & + .label:before {
      content: '';
      border: 1px solid $grey-60;
      background: $white-100;
      display: inline-block;
      vertical-align: bottom;
      width: 18px;
      height: 18px;
      margin-right: 12px;
      text-align: center;
      border-radius: 2px;
   }
   &:checked + .label:before {
      background: $dela-blue-100;
      border: none;
   }

   &:checked + .label:after {
      font-family: $icon-font;
      font-size: rem(10);
      content: '\e819';
      position: absolute;
      left: 2px;
      top: 0;
      color: $white-100;
   }

   &:disabled + .label:before {
      background-color: $grey-20;
   }
   &:disabled:checked + .label:before {
      background-color: $grey-20;
      border: 1px solid $grey-60;
   }
   &:disabled:checked + .label:after {
      color: $grey-60;
   }
}

.hasError .label:before {
   border: $input-bright-error-border;
}

.rounded {
   .checkbox {
      & + .label:before {
         background-color: $white-100;
         border: 1px solid $grey-60;
         vertical-align: middle;
         width: 23px;
         height: 23px;
         padding: 2px;
         margin-right: 12px;
         margin-left: -2px;
         border-radius: 50%;
      }
      &:checked + .label:before {
         background: $dela-blue-100;
         border-color: $dela-blue-100;
         box-shadow: inset 0 0 0 4px $white-100;
      }
      &:checked + .label:after {
         display: none;
      }
   }
   .checkbox:disabled {
      & + .label:before {
         background-color: $grey-20;
         border: $input-bright-disabled-border;
      }
      &:checked + .label:before {
         background-color: $grey-60;
      }
   }
   + .rounded {
      padding-bottom: 10px;
   }
}
