@import 'shared/styles/variables';

.add-insured-adult {
   color: $dela-blue-80;
   text-transform: uppercase;
   font-weight: 700;
   letter-spacing: 0.65px;
   font-size: 12px;
   background-color: transparent;
   width: 124px;

   &:hover,
   &:focus {
      color: $dela-blue-100;
      .icon-container {
         background-color: $dela-blue-80;
         .icon-person {
            color: $white-100;
         }
      }
   }

   .icon-container {
      width: 61px;
      height: 61px;
      border-radius: 4px;
      background-color: $dela-blue-20;
      padding: 9px 5px 5px 9px;
      margin: 0 auto 10px;
      .icon-person {
         font-size: rem(45);
      }
   }
}
