@import 'shared/styles/variables';

.Wrapper {
   position: relative;
   margin: 10px 0;
   table {
      font-family: $main-font;
      width: 100%;
      table-layout: auto;
      thead {
         background-color: $dela-blue-20;
         font-size: rem(14);
         font-weight: bold;
         color: $grey-100;
         tr:hover {
            background-color: $dela-blue-20;
         }
      }
      th {
         vertical-align: middle;
         padding-left: 15px;
      }
      tr {
         height: 40px;
         &:hover {
            background-color: $dela-blue-10;
         }
      }
      td {
         border-bottom: 1px solid $dela-blue-20;
         font-size: rem(13);
         color: $grey-100;
         vertical-align: middle;
         padding-left: 15px;
         & > a {
            display: block;
            width: 100%;
            color: $grey-100;
            padding: 7px 0;
         }
      }
   }

   .Status {
      width: 170px;
      text-align: center;
   }

   .Number,
   .DaysBeforeDiscard {
      width: 1px;
      white-space: nowrap;
   }
   .DaysBeforeDiscard {
      padding-left: 0;
   }

   .LoaderWrapper {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      opacity: 1;
      pointer-events: all;
      .ActiveLoader {
         position: absolute;
         width: 100%;
         top: 15%;
         transform: translateY(-50%);
         & > div {
            margin: 0;
         }
      }
   }

   .NoResults {
      height: 141px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
         font-family: $input-font;
         font-size: rem(14);
         text-align: center;
         color: $grey-100;

         &:before {
            display: block;
            content: '\e820';
            font-family: $icon-font;
            font-size: rem(67);
            color: $dela-blue-10;
         }
      }
   }
}
