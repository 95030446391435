@import 'shared/styles/variables';

.root {
   color: $grey-100;
   display: flex;
   align-items: flex-start;
   font-size: rem(10);

   i {
      padding-top: 2px;
      font-size: rem(10);
      margin-right: 5px;
   }
}
