@import 'shared/styles/variables';

.additional-information-details {
  hr {
    border-top: 2px solid $dela-blue-20;
    margin: 0 0 35px;
  }

  h2 {
    padding-top: 25px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .info-panel {
    margin: 0 0 15px;
  }

  .provided-info {
    height: 200px;
    margin-bottom: 8px;
  }

  .radio-button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .inline {
      padding-top: 8px;
      margin: 0 30px 0 0;
    }
  }
}
