@import 'shared/styles/variables';

.unsupported-header {
   background-color: $dela-blue-60;
   height: $header-height;
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 15;
   display: flex;
   align-items: center;
   padding-left: 9px;
}
.unsupported-browser {
   margin-top: $header-height;
   font-family: $main-font;
   color: $dela-blue-100;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: 0.67px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: fixed;
   height: 100%;
   width: 100%;
   white-space: pre;

   &-img {
      width: 245px;
      height: 213px;
      background-size: 245px 213px;
   }

   &-title {
      font-size: 28px;
      text-align: center;
      text-transform: none;

      margin: 12px 0;
      font-weight: 400;
   }

   &-info {
      margin-bottom: 15px;
   }

   p {
      font-weight: normal;
      text-align: center;
      font-size: 18px;
      color: $grey-100;
   }

   a {
      text-decoration: underline;
      font-weight: 600;
      color: $dela-blue-100;
   }
}
