@import 'shared/styles/variables';

.form-wrapper {
   max-width: 1000px;
   min-width: 800px;
   margin-bottom: 100px;
   h2 {
      margin-top: 29px;
   }

   .buttons-panel {
      margin: 25px 0;

      .button {
         margin-right: 15px;
      }
      .nav-button {
         margin-left: 10px;
      }
   }

   .buttons-panel:first-of-type {
      margin: 25px 0;
   }

   .send-notice {
      display: flex;
      align-items: center;
      background-color: $orange-20;
      border-radius: 4px;
      color: $grey-100;
      padding: 10px 15px;
      width: 100%;
      .icon-circle-exclamation {
         margin: 1px 15px 0 0;
         font-size: rem(20);
      }
   }
}
