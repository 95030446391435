@import 'shared/styles/mixins';
@import 'shared/styles/variables';

.TestEnvNotification {
   width: 100%;
   position: fixed;
   bottom: 0;
   line-height: $env-notification-height;
   background-color: $yellow-100;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: $input-font;
   font-size: 18px;
   color: $grey-120;
   z-index: 15;
   padding-bottom: env(safe-area-inset-bottom);
}
