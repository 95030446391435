@import 'shared/styles/variables';

.error-page {
   margin: 117px 0 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: $grey-100;

   h2,
   a {
      color: $dela-blue-100;
   }

   h2 {
      font-size: rem(28);
      font-weight: normal;
      text-transform: none;
      margin-bottom: 4px;
      letter-spacing: 1.29px;
   }

   & > p,
   .sales-support > p {
      font-size: rem(18);
      text-align: center;
   }

   a {
      text-decoration: underline;
   }

   a,
   p {
      text-align: center;
      margin-bottom: 6px;
   }

   &-container {
      position: relative;
      top: 0;
      left: 0;

      img {
         position: relative;
         top: 0;
         left: 0;
         width: 250px;
         height: 245px;
      }
   }

   .sales-support,
   .error-page-title {
      margin-top: 47px;
   }

   .sales-error {
      margin-top: 57px;
   }

   .error-description {
      font-weight: bold;
   }

   .report-button,
   .report-button:hover,
   .report-button:active {
      color: $white-100;
      background-color: $dela-blue-80;
      text-decoration: none;
      padding: 5px 20px;
      margin-top: 40px;
   }
}
