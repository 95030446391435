@import 'shared/styles/variables';

.root {
   display: flex;
   margin-bottom: 6px;
   color: $dela-blue-100;
   letter-spacing: 0.7px;
   align-items: center;
}

.required:after {
   font-weight: normal;
   content: '*';
}
