@import 'shared/styles/variables';

.app-popover {
   width: 300px;
   color: $white-100;
   font-size: 14px;
   font-family: $input-font;

   button {
      cursor: pointer;
      background-color: transparent;
      margin: 0;

      &:hover {
         opacity: 0.7;
      }
      .icon-cross {
         margin-top: 5px;
         color: $white-100;
      }
   }

   &-container {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: $grey-100;
      padding: 8px 15px;
   }

   &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
   }

   &-title {
      font-family: $main-font;
      font-weight: bold;
   }

   &-steps {
      margin-bottom: 15px;
      letter-spacing: 0.7px;
   }

   &-content {
      max-width: 270px;
      margin-bottom: 24px;
      letter-spacing: 0.7px;
   }

   &-actions {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      justify-content: space-between;
      background-color: $grey-120;
      letter-spacing: 0.6px;
      padding: 15px;
   }

   &-action {
      color: $white-100;
      display: flex;
      align-items: center;
      .iconfont {
         font-size: rem(10);
      }
      &.next,
      &.confirm {
         margin-left: auto;
      }
      span {
         margin: 0 10px;
      }
   }
}
