@import 'shared/styles/variables';
@import 'shared/styles/mixins';

.phone-field {
   .phone-error {
      border: $input-error-border;
      padding: 6px 8px 8px 8px;
   }

   .info-area {
      position: absolute;
      bottom: 0;
   }

   & + .info-panel {
      margin-top: 10px;
   }

   &-dropdown {
      .dropdown-input {
         width: 90px;
         font-family: $input-font;
         font-size: 14px;
         color: $grey-100;

         .dropdown-input__control {
            background-color: $grey-20;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: none !important;
            padding: 1px 0 1px 10px;
            height: 33px;
         }

         .dropdown-input__menu {
            width: 287px;

            .dropdown-input__option {
               display: flex;
               align-items: center;
               justify-content: flex-start;

               &-name {
                  margin: 0 10px;
                  width: 100%;
               }

               &-code {
                  flex-shrink: 0;
               }
            }
         }
      }
   }
}

.phone-field.hasError {
   .input-dropdown-wrapper .dropdown-input .dropdown-input__control {
      border: solid 1px $red-100;
      border-right: solid 1px $grey-60;
   }
}

.phone-field-label {
   display: block;
   z-index: 1;
   width: 100%;
   color: $dela-blue-100;
   margin-bottom: 6px;
   letter-spacing: 0.7px;
   font-family: $label-font;
}
