@import 'shared/styles/variables';

.btn {
   font-family: $main-font;
   font-size: rem(15);
   border: none;
   border-radius: $button-border-radius;
   outline: none;
   padding: 7px 10px;
   box-sizing: border-box;
   letter-spacing: normal;
   line-height: normal;
}

.btn-default,
.btn-default:focus {
   background-color: $orange-100;
   color: $white-100;
   outline: none;
   &:hover {
      background-color: $orange-40;
      color: $white-100;
      outline: none;
   }
   &:active {
      background-color: $orange-120;
      color: $white-100;
      outline: none;
   }
   &:disabled,
   &:disabled:hover {
      background: none;
      border: solid 1px $grey-20;
      box-shadow: none;
      opacity: 1;
   }
}

.btn-action,
.btn-action:focus,
.btn-convert,
.btn-fillout {
   text-align: center;
   font-weight: bold;
   padding: 7px 20px;
}

.btn-action,
.btn-action:focus {
   background-color: $green-100;
   color: $white-100;
   outline: none;
   &:hover {
      background-color: $green-60;
      color: $white-100;
      outline: none;
   }
   &:active {
      background-color: $green-120;
      color: $white-100;
      outline: none;
   }
   &:disabled,
   &:disabled:hover {
      background-color: $green-20;
      color: $white-100;
      outline: none;
      border: none;
      box-shadow: none;
      opacity: 1;
   }
}

.btn-flat,
.btn-flat:focus {
   &,
   &:active,
   &:hover,
   &:disabled {
      background: none;
      outline: none;
      color: $dela-blue-100;
      text-decoration: underline;
      box-shadow: none;
      border: none;
   }
}

.btn-fillout,
.btn-fillout:focus {
   background-color: $dela-blue-80;
   &:hover {
      background-color: $dela-blue-60;
   }
   &:active {
      background-color: $dela-blue-100;
   }
   &:disabled,
   &:disabled:hover {
      color: $grey-60;
      border: 1px solid $grey-60;
      padding: 7px 20px;
   }
}

.btn-convert {
   margin-left: 14px;
   &:disabled,
   &:disabled:hover {
      border: 1px solid $grey-60;
      color: $grey-60;
      padding: 7px 20px;
   }
}

.btn-remove {
   outline: none;
   background: none;
   margin-left: 6px;
   .icon-cross {
      color: $dela-blue-100;
      font-size: rem(14);
   }
}

.btn-add,
.btn-add:focus {
   color: $dela-blue-100;
   display: flex;
   justify-content: center;
   align-items: center;
   .icon-circle-plus {
      font-size: rem(22);
   }
}

.panel .btn-default:disabled {
   border-color: $dela-blue-100;
   color: $dela-blue-100;
   opacity: inherit;
}

.logout-btn {
   font-family: $input-font;
   font-size: 1rem;
   align-self: center;
}

.btn-nav {
   border: 1px solid $dela-blue-100;
   background-color: $white-100;
   height: 32px;
   color: $grey-100;
   padding: 0;
   span {
      position: relative;
      top: -2px;
   }
   &:before,
   &:after {
      font-family: $icon-font;
      font-size: rem(15);
      font-weight: bold;
      position: relative;
      top: 3px;
   }
   &:not(.isNext) {
      text-align: left;
      &:before {
         content: '\e81e';
      }
      span {
         padding: 0 15px 0 5px;
      }
   }
   &.isNext {
      text-align: right;
      background-color: $dela-blue-10;
      &:after {
         content: '\e81f';
      }
      span {
         padding: 0 5px 0 15px;
      }
   }
   &:hover {
      background-color: $dela-blue-80;
      border-color: $dela-blue-80;
      color: $white-100;
   }
   &:active {
      background-color: $dela-blue-100;
      border-color: $dela-blue-100;
      color: $white-100;
   }
   &:disabled,
   &:disabled:hover {
      border: 1px solid $grey-60;
      background-color: $white-100;
      color: $grey-60;
   }
   &:nth-child(2) {
      margin-left: 10px;
   }
}

.cross-button {
   cursor: pointer;
   transform: rotate(45deg);
   font-size: 20px;
   color: $grey-60;
}
