@import 'shared/styles/variables';

.root {
   display: inline-block;
   letter-spacing: 0.7px;
   color: $dela-blue-100;
}
.option {
   opacity: 0;
   position: absolute;
   & + .label:before {
      content: '';
      background-color: $white-100;
      border: 1px solid $grey-60;
      display: inline-block;
      vertical-align: middle;
      width: 23px;
      height: 23px;
      padding: 2px;
      margin-right: 6px;
      text-align: center;
      border-radius: 50%;
   }
   &:checked + .label:before {
      background: $dela-blue-100;
      border-color: $dela-blue-100;
      box-shadow: inset 0 0 0 4px $white-100;
   }
   &:disabled {
      & + .label:before {
         background-color: $grey-20;
         border: $input-bright-disabled-border;
      }
      &:checked + .label:before {
         background-color: $grey-60;
      }
   }
   &.hasError {
      & + .label:before {
         border: $input-bright-error-border;
      }
   }
}

.label {
   display: flex;
   &:before {
      flex-shrink: 0;
   }
}
.labelText {
   font-family: $input-font;
   font-size: rem(14);
   padding-top: 2px;
}
