@import 'shared/styles/variables';

.sales-process-page {
   font-size: 14px;
   min-width: 1200px;
   padding: 26px 0 0 15px;
   position: relative;

   .nav-container-sticky {
      position: sticky;
      flex: 0 0;
      height: 100%;
      top: 70px;
      /*
        z-index was added to .sticky class to make popover render above the main page
      */
      z-index: $popover-z-index;
   }
   &-content {
      flex: 1 0 80%;
   }
}

.documents-how-it-works-button-wrapper {
   .how-it-works-button {
      margin-top: 0;
   }
}
