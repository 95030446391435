@import 'shared/styles/variables';

.homepage {
  min-height: calc(100vh - #{$header-height});
  padding: 80px 16px;
  background-color: $dela-blue-10;

  .link {
    background: none;
    color: $orange-100;
    font-size: rem(13);
    font-weight: bold;
    text-decoration: underline;

    &.portfolio-title,
    &.commissions-title {
      margin-bottom: 5px;
      color: $dela-blue-100;
      font-size: rem(21);
      text-decoration: none;
      text-transform: none;
    }
  }

  .widget {
    display: flex;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: $white-100;
    color: $dela-blue-100;

    &.greeting,
    &.statistics {
      height: 135px;
    }

    &.greeting {
      background-image: url('../../../assets/images/negotiation.svg');
      background-position: bottom 0 right 5px;
      background-repeat: no-repeat;
      background-size: 209px 103px;

      .greeting-content {
        align-self: center;
        font-size: rem(17);
        text-transform: uppercase;

        .greeting-account-label {
          font-weight: bold;
        }
      }
    }

    &.statistics {
      .statistics-content {
        display: flex;
        align-items: center;
        padding: 15px 0;

        .statistics-figures {
          margin-right: 10px;
          color: $orange-100;
          font-size: rem(40);

          .overflow-tooltip {
            max-width: 140px;
          }
        }

        .statistics-text {
          font-size: rem(15);
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    &.start-button {
      padding: 0;
      background: none;

      .start-button-content {
        width: 100%;

        button {
          width: inherit;
          height: 50px;
          font-weight: bold;
        }
      }
    }

    &.portfolio,
    &.infoblock {
      padding-bottom: 45px;
    }

    &.portfolio {
      flex-direction: column;
      justify-content: flex-start;

      .portfolio-header {
        display: flex;
        align-items: baseline;

        button:last-child {
          margin-left: auto;
        }
      }

      .portfolio-content {
        .portfolio-no-data {
          padding: 0 30px;

          &-content {
            display: flex;
            height: 76px;
            flex-direction: column;
            justify-content: center;
            padding-left: 135px;
            background-image: url('../../../assets/images/folder.svg');
            background-position: bottom 0 left 0;
            background-repeat: no-repeat;
            background-size: 95px 76px;

            &-title {
              padding-bottom: 15px;
              font-weight: bold;
            }

            &-text {
              button {
                background: none;
                font-weight: bold;
                text-decoration: underline;
              }
            }
          }

          button {
            color: $dela-blue-100;
            font-size: rem(14);
            text-transform: none;
          }
        }
      }
    }

    &.infoblock {
      flex-direction: column;
      background-color: $dela-blue-80;
      color: $white-100;

      .infoblock-title {
        margin: 15px 0;
        font-size: rem(24);
      }

      span {
        font-weight: bold;
      }
    }

    &.commissions {
      position: relative;
      height: 150px;

      &::before {
        position: absolute;
        right: 45px;
        bottom: 25px;
        color: $dela-blue-20;
        content: '\e82c';
        font-family: $icon-font;
        font-size: rem(74);
      }

      .commissions-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .commissions-text {
          flex-grow: 2;
          color: $dela-blue-100;
          font-size: rem(16);
        }
      }
    }
  }
}
