@import 'shared/styles/variables';

@font-face {
   font-family: $label-font;
   src: url('../../assets/fonts/ScalaSansPro.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: $label-font;
   src: url('../../assets/fonts/ScalaSansPro-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
}
@font-face {
   font-family: $icon-font;
   src: url('../../assets/fonts/iconfont.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}
