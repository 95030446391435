@import 'shared/styles/variables';

.overlay {
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 3;
   background-color: $grey-20;
   text-align: center;
}

.loader {
   position: fixed;
   left: 50%;
   top: 50%;
   display: block;
   border: 5px solid $grey-20;
   border-top-color: $dela-blue-100;
   border-radius: 50%;
   width: 50px;
   height: 50px;
   margin: -80px 0 0 -25px;
   animation: spin 1s linear infinite;
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
