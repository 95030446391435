@import 'shared/styles/variables';

.status {
  display: inline-block;
  width: 100%;
  padding: 5px;
  border-radius: 2px;
  color: $grey-100;
  font-family: $main-font;
  font-size: rem(12);
  text-align: center;

  &-draft {
    background-color: $grey-20;
  }

  &-completed {
    background-color: $green-10;
  }

  &-in-progress {
    background-color: $yellow-20;
  }

  &-cancelled {
    background-color: $orange-20;
  }
}

.days-before-discard {
  padding: 0 15px 0 10px;
  font-size: rem(10);

  &-alert {
    color: $red-100;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.table-header {
  display: flex;
  align-items: center;
  gap: 3px;

  svg {
    cursor: pointer;

    &.sort-icon-active {
      color: #007fa4;
    }

    &.sort-icon-hidden {
      display: none;
    }
  }
}
