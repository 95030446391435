@import 'shared/styles/variables';

.input-dragndrop-wrapper {
   font-family: $input-font;
   color: $dela-blue-100;
   letter-spacing: 0.7px;
   .dropzone-container {
      display: flex;
      .dragndrop-input {
         flex-grow: 1;
         & + .dragndrop-input {
            margin-left: 15px;
         }
         cursor: pointer;
         border: 2px dashed $grey-60;
         border-radius: 4px;
         &.active {
            background-color: $dela-blue-10;
            border: 1px solid $dela-blue-100;
         }
         &.accepted {
            background-color: $dela-blue-10;
            border: none;
            cursor: default;
            &.uploading {
               border: 1px solid $dela-blue-100;
               .icon-circle-arrows {
                  font-size: 20px;
                  animation: spin 1.5s linear 0.3s infinite;
               }
            }
            .placeholder-container {
               & > div:first-of-type {
                  opacity: 0;
               }
            }
            .icon-section {
               color: $dela-blue-100;
               font-size: rem(14);
               font-style: normal;
               .replace {
                  .overflow-tooltip {
                     max-width: 120px;
                  }
                  span {
                     display: block;
                     cursor: pointer;
                     text-decoration: underline;
                     font-weight: bold;
                  }
               }
            }
         }
         &.rejected {
            border: 1px solid $red-100;
            .icon-section {
               color: $red-100;
               font-style: normal;
               & + div {
                  font-size: rem(10);
               }
            }
         }
      }
      .placeholder-container {
         display: flex;
         flex-flow: column;
         align-items: center;
         justify-content: center;
         padding: 20px 0;
         letter-spacing: normal;
         h3 {
            font-weight: bold;
            margin: 0;
         }

         & > div:first-of-type {
            span {
               font-weight: bold;
               text-decoration: underline;
            }
         }

         .icon-section {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            margin-top: 9px;
            color: $grey-60;
            img {
               margin-right: 9px;
               animation: none;
            }
            p:nth-of-type(2) {
               margin: 3px 0;
            }
         }
      }
   }
}
