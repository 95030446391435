@import 'shared/styles/variables';

.insured-adults {
   max-width: 1000px;

   h3 {
      padding-bottom: 10px;
      margin-top: 0;
   }

   .title-insured {
      display: inline-block;
   }

   .title-error {
      font-family: $input-font;
      font-size: 0.714rem;
      color: $red-100;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
   }

   .info-panel {
      margin-top: 5px;
      margin-bottom: 35px;
   }
}
