@import 'shared/styles/variables';

.root {
   display: flex;
   flex-direction: column;
   font-family: $input-font;
   letter-spacing: 0.2px;
   min-height: 35px;
   font-size: rem(10);
   padding: 5px 0;

   &.autoHeight {
      min-height: auto;
   }
}
