@import 'shared/styles/variables';

.access-denied {
   margin-top: 200px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   h2 {
      color: $dela-blue-100;
      font-size: rem(28);
      font-weight: normal;
      text-transform: none;
      margin-bottom: 17px;
      letter-spacing: 1.29px;
   }
   p {
      color: $grey-60;
      font-size: rem(18);
      font-family: $label-font;
      letter-spacing: 1.29px;
      text-align: center;
   }
   &-container {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 55px;
      img {
         position: relative;
         top: 0;
         left: 0;
         width: 250px;
         height: 245px;
      }
   }
}
