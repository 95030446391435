$dela-blue-100: #007fa4;
$dela-blue-80: #3399b6;
$dela-blue-60: #66b3c9;
$dela-blue-40: #99ccdb;
$dela-blue-20: #cce6ed;
$dela-blue-10: #e5f2f6;
$grey-120: #022938;
$grey-100: #4d738a;
$grey-60: #c4cdd3;
$grey-20: #ebebeb;
$blue-120: #003b70;
$blue-100: #1c82ef;
$blue-80: #499bf2;
$blue-60: #77b4f6;
$blue-40: #a5cdf9;
$blue-20: #d2e6fc;
$blue-10: #e8f3fe;
$orange-120: #8d471f;
$orange-100: #ed7522;
$orange-80: #f1914e;
$orange-60: #f5ad7b;
$orange-40: #f8c8a7;
$orange-20: #fce4d3;
$orange-10: #fef1e9;
$yellow-120: #c89211;
$yellow-100: #ffd044;
$yellow-60: #ffe38f;
$yellow-20: #fff6da;
$green-120: #007834;
$green-100: #75bb21;
$green-80: #91c94d;
$green-60: #add77a;
$green-40: #c8e4a7;
$green-20: #e4f2d3;
$green-10: #f1f9e9;
$red-100: #e54c42;
$white-100: #ffffff;
$purple-120: #480e66;
$purple-60: #ad8ad8;
$purple-20: #e4d8f2;

:export {
   delaBlue100: $dela-blue-100;
   grey20: $grey-20;
   red100: $red-100;
}
