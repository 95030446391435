@import 'shared/styles/variables';

.textInputWrapper {
   display: flex;
   align-items: center;
   i {
      align-self: center;
      position: absolute;
      padding: 0 10px;
      color: $grey-60;
   }
   .spinner {
      position: absolute;
      border-width: 3px;
      width: 20px;
      height: 20px;
      margin: 0;
      top: 29px;
      left: calc(100% - 40px);
   }
}

.textInput {
   display: block;
   width: 100%;
   font-family: $input-font;
   outline: none;
   border-radius: 2px;
   letter-spacing: 0.7px;
   color: $grey-100;
   padding: 6px 9px 8px 9px;
   border: $input-bright-border;

   &::placeholder {
      color: $input-placeholder-color;
   }

   &:-ms-input-placeholder {
      color: $input-placeholder-color;
   }

   &::-ms-clear {
      display: none;
   }

   &:active,
   &:focus {
      border: $input-focused-border;
      padding: 5px 8px 7px 8px;
   }

   &:disabled {
      background-color: $grey-20;
      border: $input-bright-disabled-border;
      padding: 6px 9px 8px 9px;
   }

   &.hasError {
      border: $input-bright-error-border;
      padding: 6px 9px 8px 9px;
   }

   &.icon {
      padding-left: 30px;
      &:active,
      &:focus {
         padding: 5px 8px 7px 29px;
      }
      &:disabled {
         padding: 6px 9px 8px 29px;
      }

      &.hasError {
         padding: 6px 9px 8px 29px;
      }
   }
   &.loading {
      padding-right: 35px;
   }
}
