@import 'shared/styles/variables';

.navigation-pane {
   font-family: $main-font;
   min-width: 175px;
   display: flex;
   flex-direction: column;
   margin-bottom: 25px;

   .proposal-nav {
      overflow: hidden;
      transition: max-height 0.4s ease-out;
      height: auto;
      max-height: 500px;
      &.collapsed {
         max-height: 0;
      }
   }

   .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 17px;
      &:last-child {
         margin-bottom: 5px;
      }
      .iconfont {
         height: 17px;
         width: 17px;
         left: 9px;
         position: absolute;
         font-family: $icon-font;
         background-color: $white-100;
         border-radius: 50%;
         line-height: normal;
         z-index: 1;

         &.done:before,
         &.half_done:before,
         &.not_done:before,
         &.disabled:before,
         &.rejected:before,
         &.error:before {
            font-size: rem(17);
         }

         &.done:before {
            content: '\e821';
            color: $green-100;
         }
         &.half_done:before {
            content: '\e82d';
            color: $dela-blue-100;
         }
         &.not_done:before {
            content: '\e80a';
            color: $dela-blue-100;
         }
         &.disabled:before {
            content: '\e81b';
            color: $grey-60;
         }
         &.rejected:before {
            content: '\e824';
            color: $red-100;
         }
         &.error:before {
            content: '\e822';
            color: $red-100;
         }
      }

      .nav-item-button,
      .nav-item-button:focus {
         color: $dela-blue-100;
         margin-left: 15px;
         padding: 5px 15px 5px 25px;
         border: none;
         background: none;
         line-height: normal;
         text-align: left;
         height: auto;
         &:hover,
         &:active {
            border: none;
            background: none;
            color: $dela-blue-100;
         }
         &:hover {
            text-decoration: underline;
         }
         &.active {
            font-weight: bold;
         }
         &.error {
            color: $red-100;
            &:hover {
               color: $red-100;
            }
         }
         &.disabled,
         &:disabled {
            text-decoration: none;
         }
         &.not-enabled {
            color: $grey-20;
         }
         &:before,
         &:after {
            content: '';
         }
      }
   }

   .root {
      height: 34px;
      .iconfont {
         height: 34px;
         width: 34px;
         left: 0;

         &.done:before,
         &.half_done:before,
         &.not_done:before,
         &.disabled:before,
         &.rejected:before,
         &.icon-circle-plus:before {
            font-size: rem(34);
         }
         &.icon-circle-plus:before {
            content: '\e803';
            color: $dela-blue-100;
         }
      }
      &.disabled {
         .icon-circle-plus:before,
         .convert-button {
            color: $grey-60;
         }
      }
      .nav-item-button,
      .nav-item-button:focus {
         font-weight: bold;
         font-size: rem(17);
         text-transform: uppercase;
         &:not(.convert-button) {
            &.active,
            &:hover {
               background-color: $dela-blue-10;
               border-radius: 0 2px 2px 0;
            }
         }
      }
   }
}
