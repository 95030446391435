@import 'shared/styles/variables';

.how-it-works-button {
   position: relative;
   margin: 35px 0 8px;
   background-color: transparent;
   font-weight: bold;
   span {
      display: flex;
      color: $grey-100;
      text-align: left;
      text-decoration: underline;
      &:hover {
         text-decoration: none;
      }
   }
}

.how-it-works-animation {
   animation: fade-out-in 1s;
}

@keyframes fade-out-in {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
