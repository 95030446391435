@import 'shared/styles/variables';

$divider-z-index: 5;

.popover-container {
   position: relative;

   .popover-wrapper {
      display: flex;
      position: absolute;

      &.left,
      &.right {
         .popover-divider {
            z-index: $divider-z-index;
            position: relative;
            width: 30px;

            &:before,
            &:after {
               position: absolute;
               display: block;
               content: '';
               width: 10px;
               height: 10px;
               background-color: $grey-100;
               border-radius: 50%;
            }
         }
      }

      &.right {
         left: 100%;
         flex-direction: row-reverse;
         bottom: 0;

         .popover-divider {
            border-bottom: 1px dashed $grey-100;
            margin-bottom: 15px;

            &:before {
               top: calc(100% - 5px);
               right: calc(100% - 5px);
            }
            &:after {
               display: none;
            }
         }
      }
      &.left {
         right: 100%;
         top: 0;

         .popover-divider {
            border-top: 1px dashed $grey-100;
            margin-top: 40px;

            &:before {
               display: none;
            }
            &:after {
               left: calc(100% - 5px);
               bottom: calc(100% - 5px);
            }
         }
      }

      &.animated .app-popover-container {
         animation: blink 1s;
      }
   }

   .app-popover {
      z-index: $popover-z-index;

      .iconfont.icon-arrow-right {
         position: static;
      }
   }
}

@keyframes blink {
   0% {
      background-color: $grey-60;
   }
   100% {
      background-color: $grey-100;
   }
}
